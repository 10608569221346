@font-face {
  font-family: 'Druk';
  font-style: normal;
  font-weight: 400;
  src: local('Druk'), url('shared/assets/fonts/Druk.ttf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  src: local('ProximaNova'), url('shared/assets/fonts/proximaNova/ProximaNovaBold.otf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bolder;
  src: local('ProximaNova'), url('shared/assets/fonts/proximaNova/ProximaNovaExtrabold.otf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: local('ProximaNova'), url('shared/assets/fonts/proximaNova/ProximaNovaRegular.otf');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: light;
  src: local('ProximaNova'), url('shared/assets/fonts/proximaNova/ProximaNovaThin.otf');
}

.video-react .video-react-big-play-button {
  width: 50px;
  height: 48px;
  border-radius: 50%;
  left: 55% !important;
}

.videoButton {
  display: none !important;
}

.show-play-button {
  display: block !important;
}

.hide-play-button, .hide-controls {
  display: none !important;
}


.ant-drawer-body {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background: rgba(9,14,46,0.9);
  background-image: url("shared/assets/icons/app_BG.webp");
  background-size: cover;
  background-position: center top;
}

.ant-popover-inner-content {
  width: 250px;
  padding: 0;
}

.ant-popover-arrow {
  display: none;
}